import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import style from './styles.module.css';

interface TwoColumnsProps {
  children?: ReactNode;
}

export const TwoColumns: FC<TwoColumnsProps> = ({ children }) => {
  return (
    <section className={classNames(style.section, style.twoCollCardsSection)}>
      {children}
    </section>
  );
};
