import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import style from './Card.module.css';

interface NumericCardProps {
  title: string;
  value: string | number;
  icon?: ReactNode;
  action?: ReactNode;
}

export const NumericCard: FC<NumericCardProps> = ({
  action,
  icon,
  title,
  value,
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.innerWrapper}>
        {icon}
        <div className={style.numericCardTitle}>{title}</div>
        <div className={style.numericCardContentValue}>{value}</div>
      </div>
      {action && (
        <div className={classNames(style.innerWrapper, style.action)}>
          {action}
        </div>
      )}
    </div>
  );
};
