'use client';

import {
  FocusAreaType,
  HomeScreenAnnualPlanCentralizedEffortDto,
  SlimCompanyDto,
} from '@thegist-for-wix/api';
import { useRouter } from 'next/navigation';
import { FC } from 'react';

import TableHeader from '@/app/_components/TableHeader/TableHeader';
import CompanyBadge from '@/components/Badge/CompanyBadge';
import FocusAreaTypeBadge from '@/components/FocusAreaTypeBadge/FocusAreaTypeBadge';
import CustomHeader from '@/components/Table/CustomHeader';
import Table from '@/components/Table/Table';
import { ColumnType } from '@/components/Table/types';

const columns = [
  { key: 'name', title: 'Name', type: ColumnType.Text },
  { key: 'type', title: 'Type', type: ColumnType.List },
  {
    key: 'companies',
    title: 'Companies working on this',
    type: ColumnType.List,
    infoOnHover: {
      header:
        'Companies which have one or more actions connected to this focus area',
      position: 'above',
    },
  },
  {
    key: 'actionsCount',
    title: '# of actions',
    type: ColumnType.Text,
    infoOnHover: {
      header: 'Total number of actions which are connected to this focus area',
      position: 'above',
    },
  },
] as const;

interface AnnualPlanTableProps {
  title: string;
  subtitle?: string;
  centralizedEfforts: Array<HomeScreenAnnualPlanCentralizedEffortDto>;
  loading?: boolean;
  error?: string;
}

export const AnnualPlanTable: FC<AnnualPlanTableProps> = ({
  centralizedEfforts,
  title,
  subtitle,
  loading,
  error,
}) => {
  const router = useRouter();
  const openInFullPage = (id: string | number) => {
    router.push(`/centralized-efforts/${id}`);
  };

  return (
    <Table
      loading={loading}
      error={error}
      columns={columns}
      onClickRow={(id) => openInFullPage(id)}
      rows={centralizedEfforts.map(
        (ce: HomeScreenAnnualPlanCentralizedEffortDto) => {
          return {
            id: ce.focusArea.id,
            name: ce.focusArea.name,
            type: ce.focusArea.types.map((type: FocusAreaType, idx) => {
              return <FocusAreaTypeBadge type={type} key={idx} />;
            }),
            companies: ce.companies.map((c: SlimCompanyDto, i) => (
              <CompanyBadge key={i} company={c} />
            )),
            actionsCount: `${ce.actionsCount}`,
          };
        },
      )}
      CustomHeader={
        <CustomHeader numColumns={columns.length}>
          <TableHeader title={title} subTitle={subtitle} />
        </CustomHeader>
      }
    />
  );
};
