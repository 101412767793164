/* eslint-disable max-lines */
'use client';

import {
  HomeActionsDto,
  HomeCompaniesDto,
  HomeKpiGroupDto,
  HomeKpisDto,
  HomeScreenAnnualPlanDto,
  HomeScreenResponseDto,
  PieChartDto,
  SlimActionDto,
} from '@thegist-for-wix/api';
import { useRouter } from 'next/navigation';
import { FC, useEffect } from 'react';

import HomeSectionTitle from './HomeSectionTitle';
import { Spinner } from './_components/Spinner/Spinner';
import {
  Doughnut,
  mapPieChartDto,
} from './_components/charts/Doughnut/Doughnut';
import { ActionsInComingTimeFrameTable } from './_components/tables/ActionsInComingTimeFrameTable';
import { AnnualPlanTable } from './_components/tables/AnnualPlanTable';
import { CompaniesFocusedOnAnnualPlanTable } from './_components/tables/AnnualPlanTable/CompaniesFocusedOnAnnualPlanTable';
import { CompaniesNoFocusedOnAnnualPlanTable } from './_components/tables/AnnualPlanTable/CompaniesNoFocusedOnAnnualPlanTable';
import { PrimeDirectivesTable } from './_components/tables/kpiTable/PrimeDirectivesTable';
import { WixKpisTable } from './_components/tables/kpiTable/WixKpisTable';
import style from './styles.module.css';

import { ActionParams } from '../../../types/ActionsParamsType';
import { KpiGroupsParams } from '../../../types/KpiGroupsParamsType';
import { ToastIconType } from '../../../types/ToastTypes';
import globalStyles from '../_styles/global-styles.module.css';

import { useCachedApi } from '@/api';
import { HighlyReliedUponCompanies } from '@/app/(protected)/home/_components/tables/CompaniesTable/HighlyReliedUponCompanies';
import { MostDependentCompanies } from '@/app/(protected)/home/_components/tables/CompaniesTable/MostDependentCompanies';
import PageHeader from '@/app/_components/PageHeader/PageHeader';
import { useToast } from '@/app/_components/Toast/ToastProvider';
import { WixHeroBanner } from '@/app/_components/WixHeroBanner/WixHeroBanner';
import { ThreeColumns } from '@/app/_components/layout/ThreeColumns';
import { TwoColumns } from '@/app/_components/layout/TwoColumns';
import { Button } from '@/components/Button/Button';
import { Card } from '@/components/Card/Card';
import { CardHeader } from '@/components/Card/CardHeader';
import { CardLink } from '@/components/Card/CardLink';
import { NumericCard } from '@/components/Card/NumericCard';
import Announcement from '@/icons/components/Announcement';
import Calendar from '@/icons/components/Calendar';
import ChatBubble from '@/icons/components/ChatBubble';
import TrendUp from '@/icons/components/TrendUp';
import User01 from '@/icons/components/User01';
import { HasMentionedImpact } from '@/types/HasMentionedImpact';

const Home: FC = () => {
  const router = useRouter();
  const { setToast } = useToast();

  const { results, loading, error } = useCachedApi(
    'home-page-data',
    (api) => api.homeControllerGetHomeData(),
    [],
  );

  useEffect(() => {
    if (error) {
      setToast('an error occurred while fetching data', ToastIconType.ERROR);
    }
  }, [error, setToast]);

  if (!results) {
    return <WixHeroBanner />;
  }

  const homeScreenResponse: HomeScreenResponseDto = results;
  const companies: HomeCompaniesDto = homeScreenResponse.companies;
  const actions: HomeActionsDto = homeScreenResponse.actions;
  const byPriority: PieChartDto = actions.byPriority;
  const byFocusArea: PieChartDto = actions.byFocusArea;
  const byPopulation: PieChartDto = actions.byPopulation;
  const kpis: HomeKpisDto = homeScreenResponse.kpis;
  const actionsInComingTimeFrame: SlimActionDto[] =
    actions.actionsInComingTimeFrame;
  const wixKpis: HomeKpiGroupDto[] = kpis.wixKpis;
  const primeDirectives: HomeKpiGroupDto[] = kpis.primeDirectives;
  const annualPlan: HomeScreenAnnualPlanDto = homeScreenResponse.annualPlan;

  const navigateToActions = (params: ActionParams) => {
    const queryParams: Record<string, string> = {};

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        queryParams[key] = value;
      }
    });

    const queryString = new URLSearchParams(queryParams).toString();
    router.push('/actions' + (queryString ? `?${queryString}` : ''));
  };

  const navigateToKpiGroups = (params: KpiGroupsParams) => {
    const queryParams: Record<string, string> = {};

    Object.entries(params).forEach(([key, value]) => {
      if (value && value !== 'none') {
        queryParams[key] = value;
      }
    });

    const queryString = new URLSearchParams(queryParams).toString();
    router.push('/kpi-groups' + (queryString ? `?${queryString}` : ''));
  };

  return (
    <div className={style.container}>
      <WixHeroBanner />
      <main className={globalStyles.main}>
        {loading && <Spinner className={style.spinner} />}
        <PageHeader
          title="Wix 2024 Plans Executive Overview"
          subtitle="Created from the Wix Annual Plan & Game Plans"
        />

        <HomeSectionTitle
          title="Discover the platform"
          subTitle="Discover what you can do with theGist for planning"
        />

        <ThreeColumns>
          <Card
            action={
              <Button
                label="Ask Me Anything"
                onClick={() => router.push('/ask-me-anything')}
              />
            }
          >
            <CardHeader
              title={'Ask me anything'}
              subTitle={'Wix planning AI'}
              icon={<ChatBubble />}
            />
            <p className={style.cardContent}>
              We’ve trained an advanced AI on all of the wix game plans data.
              You can ask freely it any question. Bear in mind you’re in the
              early Alpha product, and we’re keen to get feedback on its results
              to improve it.
            </p>
          </Card>

          <Card
            action={
              <Button
                label="Browse KPIs"
                onClick={() => router.push('/kpis')}
              />
            }
          >
            <CardHeader
              title={'Browse all KPIs'}
              subTitle={'Across all companies'}
              icon={<TrendUp />}
            />
            <p className={style.cardContent}>
              Browse all KPIs from all companies. We’ve connected all the KPIs
              from all plans. View related Wix KPIs, prime directive and more.
            </p>
          </Card>

          <Card
            action={
              <Button
                label="Browse Actions"
                onClick={() => router.push('/actions')}
              />
            }
          >
            <CardHeader
              title={'Browse all Actions'}
              subTitle={'Across all companies'}
              icon={<Calendar />}
            />
            <p className={style.cardContent}>
              Browse all actions and milestones from all companies. We’ve
              connected all actions across all game plans and programs.
            </p>
          </Card>
        </ThreeColumns>
        <ThreeColumns>
          <Card
            action={
              <Button
                label="Browse Companies"
                onClick={() => router.push('/companies')}
              />
            }
          >
            <CardHeader
              title={'Browse all Companies'}
              subTitle={'Which participated in the 2024 planning'}
              icon={<User01 />}
            />
            <p className={style.cardContent}>
              Browse and filter companies, view all of their employees, actions,
              KPIs, user voice dependent companies.
            </p>
          </Card>

          <Card
            action={
              <Button
                label="Browse all Centralized Efforts"
                onClick={() => router.push('/centralized-efforts')}
              />
            }
          >
            <CardHeader
              title={'Browse all Centralized Efforts'}
              subTitle={'Across all game plans'}
              icon={<Announcement />}
            />
            <p className={style.cardContent}>
              View all centralized efforts and prime directives as defined in
              the annual plan, and connected via the focus areas of all actions
              across all companies.
            </p>
          </Card>

          <Card
            action={
              <Button
                label="Browse all KPI Groups"
                onClick={() => router.push('/kpi-groups')}
              />
            }
          >
            <CardHeader
              title={'Browse all KPI Groups'}
              subTitle={'As defined in the Wix kpi guidelines'}
              icon={<TrendUp />}
            />
            <p className={style.cardContent}>
              View a collection of all the Wix KPIs and prime directive KPIs.
              Easily view who’s working on which KPIs, and what actions are
              planned.
            </p>
          </Card>
        </ThreeColumns>

        <HomeSectionTitle
          title="Annual plan"
          subTitle="Aggregated game plans around the annual plan"
        />

        <AnnualPlanTable
          centralizedEfforts={annualPlan.centralizedEfforts}
          title="Prime Directives & Centralized Efforts"
          subtitle="Companies working on the annual plan focus areas"
        />
        <TwoColumns>
          <CompaniesFocusedOnAnnualPlanTable annualPlan={annualPlan} />
          <CompaniesNoFocusedOnAnnualPlanTable annualPlan={annualPlan} />
        </TwoColumns>

        <HomeSectionTitle
          title="Companies"
          subTitle="Data across all Wix companies"
          url="companies"
          buttonLabel="Browse Companies"
        />

        <TwoColumns>
          <MostDependentCompanies companies={companies.mostDependent} />
          <HighlyReliedUponCompanies companies={companies.mostReliedUpon} />
        </TwoColumns>

        <HomeSectionTitle
          title="Actions"
          subTitle="All actions across all Wix companies"
          url="/actions"
          buttonLabel="Browse Actions"
        />

        <ThreeColumns>
          <NumericCard
            title={'Total Actions'}
            value={actions.totalActions}
            action={<CardLink href={'/actions'}>View all</CardLink>}
          />

          <NumericCard
            title={'Actions without Priority'}
            value={actions.actionsWithoutPriority}
            action={
              <CardLink href={'/actions?priority=none'}>View all</CardLink>
            }
          />

          <NumericCard
            title={'Actions without Potential Impact'}
            value={actions.actionsWithoutAffectedKpis}
            action={
              <CardLink
                href={`/actions?hasMentionedImpact=${HasMentionedImpact.HAS_NO_MENTIONED_IMPACT}`}
              >
                View all
              </CardLink>
            }
          />
        </ThreeColumns>

        <ThreeColumns stretch={true}>
          <Doughnut
            title={'Actions by priority'}
            data={mapPieChartDto(byPriority)}
            onClick={(param) => {
              navigateToActions({
                priority: param.label.replace(' ', '-').toLowerCase(),
              });
            }}
          />

          <Doughnut
            title={'Actions by focus area'}
            data={mapPieChartDto(byFocusArea)}
            onClick={(param) => {
              navigateToActions({ focusArea: param.label });
            }}
          />

          <Doughnut
            title={'Actions by Companies'}
            data={mapPieChartDto(byPopulation)}
            onClick={(param) => {
              navigateToActions({
                population: param.label.replace(' ', '-').toLowerCase(),
              });
            }}
          />
        </ThreeColumns>
        <ActionsInComingTimeFrameTable
          actionsInComingTimeFrame={actionsInComingTimeFrame}
          totalActions={actions.totalActionsInComingTimeFrame}
        />

        <HomeSectionTitle
          title="KPIs"
          subTitle="All KPIs across all companies"
          url="/kpis"
          buttonLabel="Browse KPIs"
        />

        <ThreeColumns>
          <NumericCard
            title={'Total kpis'}
            value={kpis.totalKpis}
            action={<CardLink href={'/kpis'}>View all</CardLink>}
          />

          <Doughnut
            title={'KPIs by type'}
            data={mapPieChartDto({
              ...kpis.byType,
              data: kpis.byType.data.map((item) => {
                if (item.name === 'None') {
                  return { ...item, name: 'Group KPI' };
                }
                return item;
              }),
            })}
            onClick={(param) => {
              navigateToKpiGroups({
                kpiType: param.label.replace(' ', '-').toLowerCase(),
              });
            }}
          />
          <Doughnut
            title={'Companies using Wix KPIs'}
            data={mapPieChartDto(kpis.byWixKpi)}
          />
        </ThreeColumns>
        <TwoColumns>
          <WixKpisTable kpis={wixKpis} />
          <PrimeDirectivesTable kpis={primeDirectives} />
        </TwoColumns>
      </main>
    </div>
  );
};

export default Home;
