import { TimeFrame } from '@thegist-for-wix/api';
import { FC } from 'react';

import Badge, { Variant } from '@/components/Badge/Badge';

const TimeFramesConfig: Record<TimeFrame, { name: string; variant: Variant }> =
  {
    [TimeFrame.CurrentMs]: {
      name: 'Current Milestone',
      variant: Variant.GREEN,
    },
    [TimeFrame.FutureMs]: { name: 'Future Milestone', variant: Variant.ORANGE },
    [TimeFrame.NextMs]: { name: 'Next Milestone', variant: Variant.RED },
    [TimeFrame.Ongoing]: { name: 'Ongoing', variant: Variant.PURPLE },
    [TimeFrame.Q1]: { name: 'Q1', variant: Variant.PINK },
    [TimeFrame.Q2]: { name: 'Q2', variant: Variant.INDIGO },
    [TimeFrame.Q3]: { name: 'Q3', variant: Variant.BLUE },
    [TimeFrame.Q4]: { name: 'Q4', variant: Variant.GREEN },
  };
const TimeFrameBadge: FC<{ value: TimeFrame }> = ({ value }) => {
  return (
    <Badge title={value} variant={TimeFramesConfig[value]?.variant}>
      {TimeFramesConfig[value]?.name ?? value}
    </Badge>
  );
};

export default TimeFrameBadge;
