import { useRouter } from 'next/navigation';
import { FC } from 'react';

import { AnnualPlanTableProps, columns, rowMapper } from './shared';

import TableHeader from '@/app/_components/TableHeader/TableHeader';
import CustomHeader from '@/components/Table/CustomHeader';
import Table from '@/components/Table/Table';

export const CompaniesFocusedOnAnnualPlanTable: FC<AnnualPlanTableProps> = ({
  annualPlan,
}) => {
  const router = useRouter();

  return (
    <Table
      columns={columns}
      rows={annualPlan.focusedCompanies.map(rowMapper)}
      onClickRow={(row: string) => {
        router.push(`/companies/${row}`);
      }}
      CustomHeader={
        <CustomHeader numColumns={columns.length}>
          <TableHeader
            title="Companies focused on annual plan"
            subTitle="Companies with high correlation to annual plan"
          />
        </CustomHeader>
      }
    />
  );
};
