import {
  HomeScreenAnnualPlanDto,
  HomeScreenAnnualPlanFocusedCompanyDto,
} from '@thegist-for-wix/api';

import { CompanyCell } from '../CompanyCell/CompanyCell';

import { ColumnType } from '@/components/Table/types';
import { formatNumber } from '@/src/utils';

export interface AnnualPlanTableProps {
  annualPlan: HomeScreenAnnualPlanDto;
}

export const columns = [
  { key: 'company', title: 'Company', type: ColumnType.Custom },
  {
    key: 'actions',
    title: '% of actions from annual plan',
    type: ColumnType.Text,
    infoOnHover: {
      header:
        'The percentage of actions connected to the Wix focus areas from the total actions of the company',
      position: 'above',
    },
  },
] as const;

export const rowMapper = (ce: HomeScreenAnnualPlanFocusedCompanyDto) => {
  return {
    id: ce.company.id,
    company: <CompanyCell company={ce.company} />,
    actions: `${formatNumber(ce.focusPercentage)}%`,
  };
};
