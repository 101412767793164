import { FC } from 'react';

import ErrorRed from '@/icons/components/ErrorRed';
import Successful from '@/icons/components/Successful';
import { ToastIconType } from '@/types/ToastTypes';

interface ToastIconProps {
  type: ToastIconType;
}

export const ToastIcon: FC<ToastIconProps> = ({ type }) => {
  switch (type) {
    case ToastIconType.INFO:
      return <Successful />;
    case ToastIconType.ERROR:
      return <ErrorRed />;
    default:
      return null;
  }
};
