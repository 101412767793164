import { FocusAreaType } from '@thegist-for-wix/api';
import { FC } from 'react';

import Badge, { Variant } from '@/components/Badge/Badge';

const focusAreaConfig = (
  groupType: FocusAreaType,
): { name: string; variant: Variant } => {
  switch (groupType) {
    case FocusAreaType.CentralizedEffort:
      return { name: 'Centralized Effort', variant: Variant.ORANGE };
    case FocusAreaType.PrimeDirective:
      return { name: 'Prime Directive', variant: Variant.GREEN };
    default:
      return { name: '', variant: Variant.BLUE };
  }
};

const FocusAreaTypeBadge: FC<{ type: FocusAreaType }> = ({ type }) => {
  const { variant, name } = focusAreaConfig(type);
  return (
    <Badge variant={variant} title={name}>
      {name}
    </Badge>
  );
};

export default FocusAreaTypeBadge;
