'use client';

import { FC } from 'react';

import styles from './styles.module.css';

interface Props {
  title: string;
  subtitle?: string;
}

const PageHeader: FC<Props> = ({ title, subtitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};

export default PageHeader;
