'use client';

import { useRouter } from 'next/navigation';
import { FC } from 'react';

import { KpiTableProps, mapper } from './shared';

import CustomHeader from '@/components/Table/CustomHeader';
import Table from '@/components/Table/Table';
import { ColumnType } from '@/components/Table/types';

const columns = [
  { key: 'name', title: 'Name', type: ColumnType.Text },
  {
    key: 'companies',
    title: 'Companies with goals',
    type: ColumnType.List,
    infoOnHover: {
      header:
        'Companies which declared KPIs which are connected to this Prime Directive KPI',
      position: 'above',
    },
  },
  {
    key: 'actionsCount',
    title: 'action',
    type: ColumnType.Text,
    infoOnHover: {
      header:
        'Total number of actions which mentioned to impact this Prime Directive KPI',
      position: 'above',
    },
  },
] as const;

export const PrimeDirectivesTable: FC<KpiTableProps> = ({ kpis }) => {
  const router = useRouter();
  return (
    <Table
      onClickRow={(id) => router.push(`/kpi-groups/${id}`)}
      columns={columns}
      rows={kpis.map(mapper)}
      CustomHeader={
        <CustomHeader numColumns={columns.length}>
          Prime Directive KPIs
        </CustomHeader>
      }
    />
  );
};
