import { SlimCompanyDto } from '@thegist-for-wix/api';
import Link from 'next/link';
import { FC } from 'react';

import styles from './styles.module.scss';

import User01 from '@/icons/components/User01';

interface CompanyCellProps {
  company: SlimCompanyDto;
}

export const CompanyCell: FC<CompanyCellProps> = ({ company }) => {
  const avatarProps = company.owner?.avatarUrl
    ? { style: { backgroundImage: `url(${company.owner?.avatarUrl})` } }
    : {};

  return (
    <div className={styles.company}>
      <div className={styles.avatar} {...avatarProps}>
        {!company.owner?.avatarUrl && <User01 />}
      </div>
      <Link href={`/companies/${company.id}`}>{company.name}</Link>
    </div>
  );
};
