'use client';

import { FC } from 'react';

import styles from './styles.module.css';

export interface SectionTitleProps {
  title: string;
  subTitle?: string;
}

const SectionTitle: FC<SectionTitleProps> = ({ title, subTitle }) => {
  return (
    <>
      <h2 className={styles.sectionTitle}>{title}</h2>
      {subTitle && <p className={styles.sectionSubTitle}>{subTitle}</p>}
    </>
  );
};

export default SectionTitle;
