import React, { FC } from 'react';

import style from './WixHeroBanner.module.css';
import { WixLogo } from './WixLogo';

interface HeroBannerProps {}

export const WixHeroBanner: FC<HeroBannerProps> = ({}) => (
  <header className={style.header}>
    <WixLogo fill="rgb(255 255 255 / 24%)" />
    <WixLogo fill="rgb(255 255 255)" width={166} />
  </header>
);
