import { HomeKpiGroupDto, SlimCompanyDto } from '@thegist-for-wix/api';

import CompanyBadge from '@/components/Badge/CompanyBadge';

export interface KpiTableProps {
  kpis: HomeKpiGroupDto[];
}

export const mapper = (kpiGroup: HomeKpiGroupDto) => {
  return {
    id: kpiGroup.kpiGroup.id,
    name: kpiGroup.kpiGroup.name,
    actionsCount: `${kpiGroup.actionsCount}`,
    companies: kpiGroup.companies.map((c: SlimCompanyDto, i) => (
      <CompanyBadge key={i} company={c} />
    )),
  };
};
