import { useRouter } from 'next/navigation';
import { FC } from 'react';

import { CompaniesTableProps, mapper } from './shared';

import TableHeader from '@/app/_components/TableHeader/TableHeader';
import CustomHeader from '@/components/Table/CustomHeader';
import Table from '@/components/Table/Table';
import { ColumnType } from '@/components/Table/types';

const columns = [
  { key: 'company', title: 'Company', type: ColumnType.Custom },
  {
    key: 'relatedCompanies',

    title: 'Depends on',
    type: ColumnType.List,
    infoOnHover: {
      header:
        'Companies which at least one action of this company is depending on.',
      position: 'above',
    },
  },
] as const;

export const MostDependentCompanies: FC<CompaniesTableProps> = ({
  companies,
}) => {
  const router = useRouter();
  return (
    <Table
      columns={columns}
      rows={companies.map(mapper)}
      onClickRow={(id) => router.push(`/companies/${id}`)}
      CustomHeader={
        <CustomHeader numColumns={columns.length}>
          <TableHeader
            title="Most dependent companies"
            subTitle="Companies which rely on a lot of the other companies to succeed"
          />
        </CustomHeader>
      }
    />
  );
};
