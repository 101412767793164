'use client';

import { Priority, SlimActionDto } from '@thegist-for-wix/api';
import { useRouter } from 'next/navigation';
import { FC } from 'react';

import styles from './styles.module.scss';

import ActionTitle from '@/components/ActionTitle/ActionTitle';
import Badge, { Variant } from '@/components/Badge/Badge';
import CompanyBadge from '@/components/Badge/CompanyBadge';
import Category from '@/components/Category/Category';
import FocusArea from '@/components/FocusArea/FocusArea';
import RelevantPopulation from '@/components/RelevantPopulation/RelevantPopulation';
import CustomHeader from '@/components/Table/CustomHeader';
import Table from '@/components/Table/Table';
import { ColumnType } from '@/components/Table/types';
import TimeFrameBadge from '@/components/TimeFrameBadge/TimeFrameBadge';

const columns = [
  { key: 'action', title: 'Action', type: ColumnType.Custom },
  { key: 'priority', title: 'Priority', type: ColumnType.List },
  { key: 'company', title: 'Company', type: ColumnType.Custom },
  { key: 'timeFrame', title: 'Time Frame', type: ColumnType.List },
  {
    key: 'focusAreas',
    title: 'Focus Areas',
    type: ColumnType.List,
    isOptional: true,
  },
  {
    key: 'relevantPopulation',
    title: 'Relevant Population',
    type: ColumnType.List,
  },
] as const;

interface ActionsInComingTimeFrameTableProps {
  actionsInComingTimeFrame: SlimActionDto[];
  totalActions: number;
}

export const ActionsInComingTimeFrameTable: FC<
  ActionsInComingTimeFrameTableProps
> = ({ totalActions, actionsInComingTimeFrame }) => {
  const router = useRouter();
  return (
    <Table
      onClickRow={(id) => router.push(`/actions/${id}`)}
      CustomHeader={
        <CustomHeader numColumns={columns.length}>
          <div className={styles.actionsInComingTimeFrameHeader}>
            Actions expected to be delivered in Q2
            <Badge variant={Variant.PURPLE}>{`${totalActions} actions`}</Badge>
          </div>
        </CustomHeader>
      }
      columns={columns}
      rows={actionsInComingTimeFrame.map((action: SlimActionDto) => {
        return {
          id: action.id,
          action: <ActionTitle action={action} />,
          priority:
            action.priorities?.map((p: Priority, i) => (
              <Category value={p} key={i} />
            )) || [],
          company: <CompanyBadge company={action.ownerCompany} />,
          timeFrame:
            action.timeFrames?.map((tf, i) => (
              <TimeFrameBadge value={tf} key={i} />
            )) ?? [],
          focusAreas:
            action.focusAreas?.map((fa, i) => (
              <FocusArea value={fa} key={i} />
            )) ?? [],
          relevantPopulation:
            action.relevantPopulations?.map((rp, i) => (
              <RelevantPopulation value={rp} key={i} />
            )) ?? [],
        };
      })}
      options={{
        pagination: {
          type: 'expand',
          props: {
            truncateTo: 7,
          },
        },
      }}
    />
  );
};
