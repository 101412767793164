import { FC, ReactNode } from 'react';

import style from './Card.module.css';

interface CardHeaderProps {
  title: string;
  subTitle: string;
  icon: ReactNode;
  // action?: ReactNode;
}

// <div className={classNames(style.innerWrapper, style.action)}>
export const CardHeader: FC<CardHeaderProps> = ({ title, subTitle, icon }) => {
  return (
    <header className={style.headerWrapper}>
      <div className={style.icon}>{icon}</div>

      <div className={style.titleWrapper}>
        <h3 className={style.title}>{title}</h3>
        <h4 className={style.subTitle}>{subTitle}</h4>
      </div>
    </header>
  );
};
