'use client';

import { FC } from 'react';

import styles from './styles.module.css';

interface TableHeaderProps {
  title: string;
  subTitle?: string;
}

const TableHeader: FC<TableHeaderProps> = ({ title, subTitle }) => {
  return (
    <h2 className={styles.header}>
      {title}
      <span>{subTitle}</span>
    </h2>
  );
};

export default TableHeader;
