import { FC } from 'react';

import { CompaniesTableProps, mapper } from './shared';

import TableHeader from '@/app/_components/TableHeader/TableHeader';
import CustomHeader from '@/components/Table/CustomHeader';
import Table from '@/components/Table/Table';
import { ColumnType } from '@/components/Table/types';

const columns = [
  { key: 'company', title: 'Company', type: ColumnType.Custom },
  {
    key: 'relatedCompanies',

    title: 'Companies depending',
    type: ColumnType.List,
    infoOnHover: {
      header:
        'Companies which at least one action of this company is depending on.',
      position: 'above',
    },
  },
] as const;

export const HighlyReliedUponCompanies: FC<CompaniesTableProps> = ({
  companies,
}) => {
  return (
    <Table
      columns={columns}
      rows={companies.map(mapper)}
      CustomHeader={
        <CustomHeader numColumns={columns.length}>
          <TableHeader
            title="Highly relied upon companies"
            subTitle="Companies which a lot of other companies rely upon"
          />
        </CustomHeader>
      }
    />
  );
};
