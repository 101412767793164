import Link from 'next/link';
import React, { FC } from 'react';

import style from './Card.module.css';

interface CardLinkProps {
  children?: React.ReactNode;
  href?: string;
}

export const CardLink: FC<CardLinkProps> = ({ href, children }) => {
  return (
    <Link className={style.cardLink} href={href ?? '#'}>
      {children}
    </Link>
  );
};
