'use client';

import { useRouter } from 'next/navigation';
import { FC } from 'react';

import styles from './styles.module.css';

import { Button } from '../../../components/Button/Button';
import SectionTitle, {
  SectionTitleProps,
} from '../../_components/Typography/SectionTitle';

interface HomeSectionTitleProps extends SectionTitleProps {
  title: string;
  subTitle?: string;
  url?: string;
  buttonLabel?: string;
}

const HomeSectionTitle: FC<HomeSectionTitleProps> = ({
  title,
  subTitle,
  url,
  buttonLabel,
}) => {
  const router = useRouter();
  return (
    <div className={styles.sectionTitleWrapper}>
      <div>
        <SectionTitle title={title} subTitle={subTitle} />
      </div>

      {buttonLabel && url && (
        <Button label={buttonLabel} onClick={() => router.push(url)} />
      )}
    </div>
  );
};

export default HomeSectionTitle;
