import { SlimCompanyAndRelatedCompaniesDto } from '@thegist-for-wix/api';

import { CompanyCell } from '../CompanyCell/CompanyCell';

import CompanyBadge from '@/components/Badge/CompanyBadge';

export interface CompaniesTableProps {
  companies: SlimCompanyAndRelatedCompaniesDto[];
}

export const mapper = (company: SlimCompanyAndRelatedCompaniesDto) => {
  return {
    id: company.company.id,
    company: <CompanyCell company={company.company} />,
    relatedCompanies: company.relatedCompanies.map((c, i) => (
      <CompanyBadge key={i} company={c} />
    )),
  };
};
