import { FC } from 'react';

interface WixLogoProps {
  fill: string;
  width?: number;
}
export const WixLogo: FC<WixLogoProps> = ({ fill, width }) => (
  <svg width={width ?? '393.762'} height="152.451" viewBox="0 0 104.183 40.336">
    <path
      d="M104.183 0h-5.755a4.814 4.814 0 0 0-3.985 2.113l-7.696 11.353a.517.517 0 0 1-.855 0L78.196 2.113A4.812 4.812 0 0 0 74.21 0h-5.754l13.71 20.225-13.635 20.111h5.755a4.815 4.815 0 0 0 3.986-2.113l7.62-11.24a.517.517 0 0 1 .855 0l7.62 11.24a4.811 4.811 0 0 0 3.985 2.113h5.755L90.473 20.225Zm-47.57 4.126v36.21h2.751a4.126 4.126 0 0 0 4.127-4.126V0h-2.75a4.126 4.126 0 0 0-4.127 4.126ZM51.573 0h-2.448a5.77 5.77 0 0 0-5.627 4.491l-5.476 24.071-4.793-22.47c-.886-4.153-5.147-6.953-9.507-5.75-2.777.768-4.836 3.114-5.437 5.932L13.54 28.53 8.074 4.492A5.774 5.774 0 0 0 2.445 0H0l9.173 40.334h3.475a6.684 6.684 0 0 0 6.538-5.29L25.05 7.525a.746.746 0 0 1 .726-.588c.349 0 .655.247.727.588l5.87 27.521a6.683 6.683 0 0 0 6.536 5.288h3.488z"
      style={{ fill, strokeWidth: 0.630251 }}
    />
  </svg>
);
