'use client';

import { FloatingPortal } from '@floating-ui/react';
import classNames from 'classnames';
import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import style from './Toast.module.scss';
import { ToastIcon } from './ToastTypes';

import CloseIcon from '@/icons/components/CloseIcon';
import { ToastIconType } from '@/types/ToastTypes';

interface ToastContextValue {
  setToast: (toast: string, icon?: ToastIconType) => void;
}

export const ToastContext = createContext<ToastContextValue>({
  setToast: () => {},
});

export const useToast = () => useContext(ToastContext);

interface Toast {
  id: string;
  message: string;
  icon: ToastIconType;
  visible: boolean;
}

interface ToastProviderProps {
  children: ReactNode;
  duration?: number;
}

export const ToastProvider: FC<ToastProviderProps> = ({
  children,
  duration = 5000,
}) => {
  const [toastQueue, setToastQueue] = useState<Toast[]>([]);

  const closeToast = useCallback((id: string) => {
    setToastQueue((prevQueue) => prevQueue.filter((toast) => toast.id !== id));
  }, []);

  const setToast = useCallback(
    (message: string, icon: ToastIconType = ToastIconType.INFO) => {
      const id = uuidv4();
      setToastQueue((prevQueue) => [
        ...prevQueue,
        { id, message, icon, visible: false },
      ]);

      setTimeout(() => {
        setToastQueue((prevQueue) =>
          prevQueue.map((toast) =>
            toast.id === id ? { ...toast, visible: true } : toast,
          ),
        );
      }, 0);

      setTimeout(() => closeToast(id), duration);
    },
    [duration, closeToast],
  );

  return (
    <ToastContext.Provider value={{ setToast }}>
      {children}
      <FloatingPortal>
        <div className={style.toastContainer}>
          {toastQueue.map((toast) => (
            <div
              id={toast.id}
              key={toast.id}
              className={classNames(style.toast, {
                [style.visible]: toast.visible,
              })}
            >
              <div className={style.iconContainer}>
                <ToastIcon type={toast.icon} />
              </div>
              <div className={style.toastText}>{toast.message}</div>
              <button
                className={style.closeButton}
                onClick={() => closeToast(toast.id)}
              >
                <span>
                  <CloseIcon />
                </span>
              </button>
            </div>
          ))}
        </div>
      </FloatingPortal>
    </ToastContext.Provider>
  );
};
